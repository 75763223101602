import Axios from 'axios'
import { API_URL } from '@/common/config'

export default class loginService {
  constructor () {
    Axios.defaults.headers.common['Access-Control-Allow-Origin'] = API_URL
  }

  // login
  static async login (user, pass) {
    return await Axios.post(API_URL + '/token', 'grant_type=password&username=' + user + '&password=' + pass, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
  }

  // forgotten passsord
  static async forgottenPassword (email) {
    return await Axios.post(API_URL + '/api/game/forgottenpassword?email=' + email, null, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
  }

  // set password
  static async setPassword (userId, resetToken, newPassword) {
    return await Axios.get(API_URL + '/api/game/setPassword?userId=' + userId + '&resetToken=' + resetToken + '&newPassword=' + newPassword, null, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
  }

  // reset account
  // static async resetAccount (email, guid) {
  //  return await Axios.post(API_URL + '/api/game/resetaccount?email=' + email + '&guid=' + guid, null, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
  // }

  // sign up
  static async signUp (newUser) {
    return await Axios.post(API_URL + '/api/game/signup', newUser, { headers: { 'Content-Type': 'application/json' } })
  }
}
