<template>
  <div style="border-radius: 5px;width: calc(100% + 33px);height: 100%; overflow: auto;padding:0px !important;margin-top:-16px;font-size: 0.9rem;">
    <ul
      v-if="page !== 'forgottenPassword'"
      class="nav nav-tabs d-flex justify-content-around mb-5 font-weight-bold nav-border-bottom"
      style="background:white !important;"
    >
      <li class="nav-item text-center" style="width: 50%;outline:none;">
        <a
          class="nav-link"
          :class="{'isActive': page === 'login' }"
          @click="page = 'login'"
        >LOGIN</a>
      </li>
      <li class="nav-item text-center" style="width: 50%;outline:none;">
        <a
          class="nav-link "
          :class="{'isActive': page === 'signup' }"
          @click="page = 'signup'"
        >REGISTER</a>
      </li>
    </ul>

    <div v-else style="padding:22px;">
      <h2 class="primary-colour font-weight-bold text-center mb-0" style="font-size: clamp(1rem, 2vw, 2rem); ">
        FORGOTTEN PASSWORD
      </h2>
    </div>

    <div
      class="p-3"
      style="margin-top:10px;"
    >
      <Login
        v-if="page === 'login'"
        @setPage="page = $event"
      />
      <ForgottenPassword
        v-if="page === 'forgottenPassword'"
        @setPage="page = $event"
      />
      <SignUp
        v-if="page === 'signup'"
        @setPage="page = $event"
      />
      <Pre
        v-if="page === 'pre'"
        @setPage="page = $event"
      />
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import Login from './login.vue'
import ForgottenPassword from './forgottenPassword.vue'
import SignUp from './signUp.vue'
import Pre from './pre.vue'

export default {
  name: 'AuthPanel',
  components: { Login, ForgottenPassword, SignUp, Pre },
  props: {
    accentColour: {
			type: String,
			default: "#1FFC35"
		}
  },
  data () {
    return {
      page: 'login',
      visibility: 'password',
      buttonClick: false
    }
  },
  computed: {
    ...mapGetters([
      'mobileView',
      'userData'
    ])
  },
  created () {
    if (this.userData.aut) {
      this.$router.push('/basket/checkout')
    }
  }
}
</script>

<style scoped>
.isActive {
  background: var(--pr-color) !important;
  border: none !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: black !important;
}
.nav-link {
  background: var(--bg-color);
  border: none !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: white;
}
.nav-border-bottom {
  border-bottom: 5px solid var(--pr-color) !important;
}
</style>
