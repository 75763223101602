<template>
  <div style="margin-top:-30px;" class="mobileViewMarginTop">
    <div
      v-if="done"
      style="font-size: 0.9rem;margin-top: 0px;color: var(--override-color)"
      class="primary-colour"
    >
      If your email address is registered with us, you will have been sent a reset email with further instructions. Click <a
        href="#"
        class="white-text"
        @click="$emit('setPage', 'login')"
      >here</a> to login.
    </div>
    <div v-else>
      <div style="font-size: 0.9rem;margin-top: 0px;color: var(--override-color)" class="primary-colour">
        To reset your password, please enter your registered email address. If you already know your login details, then click <a
          href="#"
          class="ml-1 white-text"
          @click="$emit('setPage', 'login')"
        >here</a>.
      </div>
      <validation-observer>
        <form>
          <div class="md-form">
            <div class="md-form form-sm" style="margin-top:25px;">
              <validation-provider
                mode="lazy"
                name="Email"
                rules="required"
              >
                <i class="fas fa-user prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:gray;" />
                <mdb-input
                  v-model="userName"
                  type="email"
                  label="Email"
                  style="margin-left:33px;"
                  size="sm"
                />
              </validation-provider>
            </div>
          </div>
        </form>
      </validation-observer>
      <div class="mt-3 d-flex justify-content-end">
        <mdb-btn
          v-show="!buttonClick"
          class="btn primary-btn btn-radius m-0"
          style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;background-color: var(--override-color) !important"
          @click="doForgottenPassword()"
        >
          RESET
        </mdb-btn>
        <mdb-btn
          v-show="buttonClick"
          class="btn primary-btn btn-radius m-0"
          style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;background-color: var(--override-color) !important"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import loginService from '@/api-services/login.service'

export default {
  name: 'ForgottenPassword',
  data () {
    return {
      userName: '',
      buttonClick: false,
      done: false
    }
  },
  computed: {
    ...mapGetters([
      'mobileView'
    ])
  },
  methods: {
    doForgottenPassword: async function () {
      this.buttonClick = true
      loginService.forgottenPassword(this.userName).then((results) => {
        console.log(results.data)
        this.buttonClick = false
        this.done = true
      }).catch(() => {
        this.done = true
      })
    },
    closeForgottenPassword () {
      this.$emit('setPage', 'login')
    }
  }
}
</script>
