<template>
  <div style="margin-top:-30px;" class="mobileViewMarginTop">
    <div>
      <div
        v-if="!showErrorMessage"
        style="font-size: 0.9rem;margin-top: 0px;color: var(--override-color)"
      >
        Please enter your email and password to login. If you have forgotten password, then click <a
          href="#"
          class="white-text"
          @click="forgottenPassword()"
        >here</a>.
      </div>
      <div
        v-else
        class="white-text"
        style="font-size: 0.9rem;margin-top: 0px;"
      >
        <div
          v-if="errorMessage != ''"
          style="border: solid 2px red;padding: 10px;"
        >
          <span style="color:red;">{{ errorMessage }}</span>      
          <span style="color:red;"> If you have forgotten password, then click <a
            href="#"
            class="white-text"
            @click="forgottenPassword()"
          >here</a>.</span>
        </div>
      </div>
      <validation-observer>
        <form>
          <div class="md-form" style="margin-top:50px;">
            <div class="md-form form-sm" style="height: 40px;">
              <validation-provider
                mode="lazy"
                name="Email"
                rules="required"
              >
                <i class="fas fa-user prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:white;" />
                <mdb-input
                  v-model="userName"
                  type="email"
                  label="Email"
                  style="margin-left:33px;color:white;"
                  size="sm"
                />
              </validation-provider>
            </div>
            <div class="md-form form-sm" style="height: 40px;">
              <validation-provider
                mode="lazy"
                name="Password"
                rules="required"
              >
                <i class="fas fa-lock prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:white;" />
                <mdb-input
                  id="input-password"
                  v-model="passWord"
                  :type="visibility"
                  label="Password"
                  style="margin-left:33px;margin-top:40px;font-family:Arial, Helvetica, sans-serif !important;border"
                  size="sm"
                />
                <a
                  v-if="visibility == 'password'"
                  style="position:absolute;right:10px;top:9px;"
                  @click="toggleVisibility()"
                ><i class="fas fa-eye grey-text" /></a>
                <a
                  v-if="visibility == 'text'"
                  style="position:absolute;right:10px;top:9px;"
                  @click="toggleVisibility()"
                ><i class="fas fa-eye-slash grey-text" /></a>
              </validation-provider>
            </div>
          </div>
        </form>
      </validation-observer>
    </div>
    <div class="mt-3 d-flex justify-content-end">
      <mdb-btn
        v-show="!buttonClick"
        class="btn primary-btn btn-radius m-0"
        style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;background-color: var(--override-color) !important;"
        @click="doLogin()"
      >
        LOGIN
      </mdb-btn>
      <mdb-btn
        v-show="buttonClick"
        class="btn primary-btn btn-radius m-0"
        style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;background-color: var(--override-color) !important;"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import loginService from '@/api-services/login.service'
import basketService from '@/api-services/basket.service'

export default {
  name: 'Login',
  data () {
    return {
      visibility: 'password',
      passWord: '',
      userName: '',
      buttonClick: false,
      showErrorMessage: false,
      errorMessage: '',
      page: window.location.hash,
    }
  },
  computed: {
    ...mapGetters([
      'mobileView',
      'userData',
      'currentBasket'
    ])
  },
  methods: {
    toggleVisibility () {
      this.visibility = this.visibility === 'password' ? 'text' : 'password'
    },
    forgottenPassword () {
      this.$emit('setPage', 'forgottenPassword')
    },
    signUp () {
      this.$emit('setPage', 'signup')
    },
    async addAnyNewCardsToBackEnd () {
      // needs to now add any backend saved basket to any cards created before login
      for (const b of this.currentBasket) {
        // basket items are added backend when selecting "Add to Basket" only if you are logged in at that time
        // any item in the basket without a BasketOrdersId has been added anonymously so these must now be added backend
        if (!b.BasketOrdersId) {
          await basketService.addToBasket(b, this.userData.access_token)
        }
      }
    },
    async doLogin () {
      this.buttonClick = true
      try {
        const results = await loginService.login(this.userName, this.passWord)
        this.$store.commit('storeUserData', results.data)
        this.$store.commit('storeRootData', true)

        if (results.data.led) {
          localStorage.setItem('clubUrl', results.data.url)
        }

        if (this.page === '#/leaderboard') { 
          window.location.reload()
        } else {
          await this.addAnyNewCardsToBackEnd()
          const basketRes = await basketService.getMyBasket(results.data.access_token)
          this.resetBasket()
          this.$nextTick(() => {
            this.$store.commit('setBasket', basketRes.data)
            if (this.$route.path === '/play/auth') {
              this.$router.push({ path: '/play', query: { page: 1 } })
              this.$store.commit('storePanelViewedOnMobile', 'Left')
            } else {
              this.$router.push('/basket/checkout')
              this.$store.commit('storePanelViewedOnMobile', 'Left')
            }
          })
        }

      } catch (error) {
        // not getting error. why?
        this.showErrorMessage = true
        try {
          this.errorMessage = Object.assign({}, error).response.data.error_description
        } catch { this.errorMessage = '' }
      }
      this.buttonClick = false
    },
    resetBasket () {
      this.addedToBasket = false
      this.$store.commit('storeHero', 0)
      this.$store.commit('storeSport', 0)
      this.$store.commit('storeColour', '#e1e1e1')
      this.$store.commit('storeNumber', 0)
      this.$store.commit('storeFirstInitial', '')
      this.$store.commit('storeSecondInitial', '')
    },
  }
}
</script>

<style>
.md-form input[type="email"]:not(.browser-default):focus:not([readonly])
{
  border-bottom: solid 1px var(--override-color) !important;
  box-shadow: 0 1px 0 0 var(--override-color) !important; 
}
.md-form input[type="password"]:not(.browser-default):focus:not([readonly])
{
  border-bottom: solid 1px var(--override-color) !important;
  box-shadow: 0 1px 0 0 var(--override-color) !important; 
}
</style>
