<template>
  <div style="margin-top:30px;" class="mobileViewMarginTop">
    <h2 class="primary-colour font-weight-bold text-center mt-2 mt-lg-3 mobileAdjustment" style="height: 30px !important;font-size: clamp(1.1rem, 2vw, 2.5rem)">
      <AnimatedWord :word="title" />
    </h2>

    <div style="text-align:center;margin: 30px 0px 30px 0px;">
      Do you want to add another hero?
    </div>

    <div class="mt-3 d-flex justify-content-center">
      <mdb-btn
        class="btn primary-btn btn-radius m-0"
        style="width:150px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        @click="addAnother()"
      >
        ADD ANOTHER
      </mdb-btn>
    </div>
    
    <div style="text-align:center;margin: 30px 0px 30px 0px;">
      Or do you want to checkout? You will need to login or register before proceeding.
    </div>
    
    <div class="mt-3 d-flex justify-content-center">
      <mdb-btn
        v-show="!buttonClick"
        class="btn primary-btn btn-radius m-0"
        style="width:150px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        @click="doLogin()"
      >
        CHECKOUT NOW
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AnimatedWord from "@/components/UI/bouncingWordWrapper.vue"

export default {
  name: 'Checkout',
  components: {
    AnimatedWord
  },
  props: {
  },
  data () {
    return {
      title: 'HERO ADDED'
    }
  },
  computed: {
    ...mapGetters([
      'mobileView',
      'userData',
      'currentBasket'
    ])
  },
  methods: {
    addAnother () {
      this.$router.push({ path: '/play', query: { page: 1 } })
    },
    doLogin () {
      this.$router.push({ path: '/play/auth', query: { page: 1 } }).catch(() => {})
    },
  }
}
</script>
